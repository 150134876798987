import * as React from 'react';
import {updateProfile, acceptedProfileTos} from '../../services/API';
import {ProfileUpdateForm} from './ProfileUpdateForm';
import {ProfileUpdateOnlyTos} from './ProfileUpdateOnlyTos';
import {MainLayout} from '../Layout/MainLayout';

export const Profile = (props) => {
  return (
    <MainLayout className={'signup-content-auth0'} hideSideComponent={true}>
      {props.onlyTos ? (
        <ProfileUpdateOnlyTos acceptTos={acceptedProfileTos} {...props} />
      ) : (
        <ProfileUpdateForm updateProfile={updateProfile} {...props} />
      )}
    </MainLayout>
  );
};
