import {Alert, Button, Card, Checkbox, Col, Form, Typography} from 'antd';
import * as React from 'react';
import {FC} from 'react';
// @ts-ignore
import CriblIcon from '../../static/svgs/cribl-logo.svg';
// @ts-ignore
import * as sharedStyles from '../../styles/shared.module.less';

// @ts-ignore
import * as styles from './signup.module.less';
import cx from 'classnames';
import {useFlags} from 'launchdarkly-react-client-sdk';

const {Title} = Typography;

export type ProfileUpdateFormProps = {
  acceptTos: (state: string) => void;
  message: string;
  state: string;
  sessionToken: string;
};

export const ProfileUpdateOnlyTos: FC<ProfileUpdateFormProps> = (props) => {
  const {cloudZeusUiOemTosUrl} = useFlags();
  const [form] = Form.useForm();
  const [tosAccepted, setTosAccepted] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const defaultTosURL = 'http://cribl.io/terms-and-conditions';
  const onFinish = () => {
    setIsSubmitting(true);
    props.acceptTos(props.state);
  };

  const onCheckboxChange = async (e: any) => {
    await setTosAccepted(e.target.checked);
    await form.validateFields(['tosAccepted']);
  };

  const checkboxValidation = () => {
    return new Promise<void>((resolve, reject) => {
      if (tosAccepted) {
        resolve();
      } else {
        reject('You must agree to the Cribl Terms of Service.');
      }
    });
  };

  return (
    <div>
      {/* for some reason gatsby is copying the classes from the Card below up to the div above it, */}
      {/* putting a span around this Alert stops that from happening. */}
      {props.message && (
        <span>
          <Alert message={props.message} type='warning' className={styles.alert} />
        </span>
      )}

      <Card className={cx('main-card-auth0', styles.mainCardProfiling)}>
        <div className={sharedStyles.iconContainerAuth0}>
          <CriblIcon style={{height: 40}} />
        </div>
        <Col className={sharedStyles.topSectionAuth0}>
          <Title level={3}>Sign up</Title>
        </Col>
        <Form name='basic' form={form} className={'main-form-section-auth0'} onFinish={onFinish}>
          <Col>
            <Form.Item name='tosAccepted' rules={[{validator: checkboxValidation}]}>
              <Checkbox checked={tosAccepted} onChange={onCheckboxChange} style={{color: '#000'}}>
                I agree to the Cribl{' '}
                <a data-testid='tos' href={cloudZeusUiOemTosUrl || defaultTosURL} target={'_blank'} rel='noreferrer'>
                  Terms of Service
                </a>
                .
              </Checkbox>
            </Form.Item>
          </Col>
          <>
            <Col>
              <Button
                type='primary'
                block={true}
                className={'orange-button-auth0'}
                htmlType='submit'
                disabled={isSubmitting}
              >
                Continue
              </Button>
            </Col>
          </>
        </Form>
      </Card>
    </div>
  );
};
