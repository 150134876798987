import {Alert, Button, Card, Checkbox, Col, Form, Input, Typography} from 'antd';
import * as React from 'react';
import jwt_decode from 'jwt-decode';
import {FC} from 'react';
// @ts-ignore
import CriblIcon from '../../static/svgs/cribl-logo.svg';
import {UpdateProfileRequest} from '../../services/API';
// @ts-ignore
import * as sharedStyles from '../../styles/shared.module.less';

// @ts-ignore
import * as styles from './signup.module.less';
import cx from 'classnames';

const {Title} = Typography;

export type ProfileUpdateFormProps = {
  updateProfile: (updateRequest: UpdateProfileRequest, state: string, sessionToken: string) => void;
  message: string;
  state: string;
  sessionToken: string;
};

export const ProfileUpdateForm: FC<ProfileUpdateFormProps> = (props) => {
  const [form] = Form.useForm();
  const [tosAccepted, setTosAccepted] = React.useState(false);
  const {isSocial} = jwt_decode(props.sessionToken) as any;
  const onFinish = (values: any) => {
    const updateProfileRequest: UpdateProfileRequest = {
      firstName: values.firstName,
      lastName: values.lastName,
      company: values.company,
      phoneNumber: values.phoneNumber,
      tosAccepted: tosAccepted
    };

    props.updateProfile(updateProfileRequest, props.state, props.sessionToken);
  };

  const onCheckboxChange = async (e: any) => {
    await setTosAccepted(e.target.checked);
    await form.validateFields(['tosAccepted']);
  };

  const checkboxValidation = () => {
    return new Promise<void>((resolve, reject) => {
      if (tosAccepted) {
        resolve();
      } else {
        reject('You must agree to the Cribl Terms of Service.');
      }
    });
  };

  return (
    <div>
      {/* for some reason gatsby is copying the classes from the Card below up to the div above it, */}
      {/* putting a span around this Alert stops that from happening. */}
      {props.message && (
        <span>
          <Alert message={props.message} type='warning' className={styles.alert} />
        </span>
      )}

      <Card className={cx('main-card-auth0', styles.mainCardProfiling)}>
        <div className={sharedStyles.iconContainerAuth0}>
          <CriblIcon style={{height: 40}} />
        </div>
        <Col className={sharedStyles.topSectionAuth0}>
          <Title level={3}>Sign up</Title>
        </Col>
        <Form name='basic' form={form} className={'main-form-section-auth0'} onFinish={onFinish}>
          {!isSocial && (
            <Col>
              <Form.Item
                name='firstName'
                rules={[
                  {
                    required: true,
                    message: 'Please enter your first name!'
                  },
                  {whitespace: true, message: 'First name cannot be a whitespace'}
                ]}
              >
                <Input placeholder={'First name'} />
              </Form.Item>
            </Col>
          )}
          {!isSocial && (
            <Col>
              <Form.Item
                name='lastName'
                rules={[
                  {
                    required: true,
                    message: 'Please enter your last name!'
                  },
                  {whitespace: true, message: 'Last name cannot be a whitespace'}
                ]}
              >
                <Input placeholder={'Last name'} />
              </Form.Item>
            </Col>
          )}
          <Col>
            <Form.Item
              name='company'
              rules={[
                {required: true, message: 'Please enter your company!'},
                {
                  type: 'string',
                  message: 'Enter a valid company.'
                },
                {whitespace: true, message: 'Company cannot be a whitespace'}
              ]}
            >
              <Input placeholder={'Company'} />
            </Form.Item>
          </Col>

          {/*Lord forgive for regexing a phone number*/}
          <Col>
            <Form.Item
              name='phoneNumber'
              rules={[
                {
                  pattern: new RegExp(/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/m),
                  message: 'Please input a valid phone number. Ex: (555.555.5555 or 555-555-5555)'
                }
              ]}
            >
              <Input placeholder={'Phone Number (optional)'} />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item name='tosAccepted' rules={[{validator: checkboxValidation}]}>
              <Checkbox checked={tosAccepted} onChange={onCheckboxChange} style={{color: '#000'}}>
                I agree to the Cribl{' '}
                <a data-testid='tos' href='http://cribl.io/terms-and-conditions' target={'_blank'} rel='noreferrer'>
                  Terms of Service
                </a>
                .
              </Checkbox>
            </Form.Item>
          </Col>
          <>
            <Col>
              <Button type='primary' block={true} className={'orange-button-auth0'} htmlType='submit'>
                Continue
              </Button>
            </Col>
          </>
        </Form>
      </Card>
    </div>
  );
};
