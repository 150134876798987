import * as React from 'react';
import '../styles/App.less';
import {Helmet} from 'react-helmet';
import {setUTMCookie} from '../utils/CookieUtil';
import {PageProps} from 'gatsby';
import {Profile} from '../components/SignUp/Profile';
import {Result, Spin} from 'antd';
import {useAuth} from '../components/Auth/AuthContext';

// markup
const IndexPage = (props: PageProps) => {
  setUTMCookie(props);
  const {loginWithRedirect} = useAuth();
  const search = props.location?.search || '';
  const params = new URLSearchParams(search);

  const m = params.get('m');
  const error = params.get('error');
  const errorDescription = params.get('error_description');
  const authState = params.get('state');
  const sessionToken = params.get('session_token');
  const onlyTos = params.get('onlyTos') === 'true';
  let message;
  if (m === 'NoAccount') {
    message =
      'We don’t have a Cribl account associated with the Google user that you used to sign in. Please use the form below to finish signing up.';
  }

  if (!sessionToken) {
    loginWithRedirect({redirect_uri: '/'});
    return (
      <main className={'main-holder-auth0'}>
        {error && <Result status='error' title={error} subTitle={errorDescription} />}
        {!error && <Spin size='large' className={'spinner-auth0'} />}
      </main>
    );
  }

  return (
    <>
      <Helmet htmlAttributes={{lang: 'en'}}>
        <meta charSet='utf-8' />
        <title>Cribl.Cloud - Signup</title>
        <link rel='canonical' href='https://cribl.cloud/register' />
      </Helmet>
      <main className={'main-holder-auth0'}>
        <Profile message={message} state={authState} sessionToken={sessionToken} onlyTos={onlyTos} />
      </main>
    </>
  );
};

export default IndexPage;
